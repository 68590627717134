import axios from "axios";
import Config from "../config";

const getRoomAds = room_id => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/room/get-ads",
        data: { room_id }
    });
};

export default getRoomAds;

import axios from "axios";
import Config from "../config";

const getRoom = id => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v3/join-room",
        data: { id }
    });
};

export default getRoom;

import axios from "axios";
import Config from "../config";

const summaryConversation = (session_id) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/summary",
        data: {session_id}
    });
};

export default summaryConversation;

import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import {Link} from "react-router-dom";
import icons from "../../../../assets";
import Config from "../../../../config";
import ReportTopCustomerApi from "../../../../api/report/ReportTopCustomer";

const TopCustomer = ({selectedChannel, selectedTime}) => {
    const [topCustomer, setTopCustomer] = useState([]);
    let brand = useGlobal('brand')[0];
    const parentDomain = useGlobal('parentDomain')[0];

    useEffect(() => {
        const start_date = selectedTime[0];
        const end_date = selectedTime[1];

        ReportTopCustomerApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            setTopCustomer(data);
        })
        .catch(err => console.log(err));
    }, [selectedTime, selectedChannel]);

    const handleIconChannel = (source) => {
        if(source === 'facebook'){
            return icons.Facebook;
        }
        else if(source === 'zalo'){
            return icons.Zalo;
        }
        else{
            return icons.ClientChat;
        }
    }

    const getAvatar = (customer) => {
        let customerName = customer.customer_name;
        if (customer.avatar){
            return <img
                src={`${Config.url || ''}/api/images/${customer.avatar}/256/${brand}`}
                alt="" width="50" height="50"
                className="staff-overview-avatar customer-avatar-undefined" draggable="false"
            />  
        }
        else if(customerName){
            return <div className="img">{customerName.substr(0,1).toUpperCase()}</div>;
        }

        return <div className="img">N</div>;
    }

    return (
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Khách hàng tương tác</div>
                    <div className="desc">Top khách hàng có tương tác nhiều nhất với trang</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content" style={{ height: 'unset' }}>
            {topCustomer && topCustomer.length > 0 && (
                <div className='staff-overview-content'>
                    {topCustomer.map((customer, index) => (<div key={customer._id} className="staff-item">
                        <img width="25" src={handleIconChannel(customer.source)} alt="medal"/>
                        <div className="avatar-and-rank">
                            {getAvatar(customer)}
                            <div className="rank">{index + 1}</div>
                        </div>
                        <div className="detail">
                            <div className="name">
                                {customer.customer_id ? <img className="mr-2" width="16" src={icons.Customer} alt=""/> : (customer.lead_id ? <img className="mr-2" width="16" src={icons.CpoCustomer} alt=""/> : '') } 
                                {/* {customer.social_name} */}
                                <Link to={`/room/${customer._id}`} className="un-link" style={{ fontWeight: 600 }}>{customer.social_name}</Link>
                                {customer.customer_id 
                                ? <a href={`${parentDomain}/admin/customer/detail/${customer.customer_id}`} className="un-link" style={{ color: '#0048ac', marginLeft: '5px' }} target="_blank" rel="noreferrer">({customer.full_name})</a>
                                : (customer.lead_id ? <a href={`${parentDomain}/customer-lead/customer-lead/detail/${customer.lead_id}`} className="un-link" style={{ color: '#0048ac', marginLeft: '5px' }} target="_blank" rel="noreferrer">({customer.full_name})</a> : '')}
                            </div>
                            <div className="engagement">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 256 256" className="icon">
                                    <path d="M169.57,72.59A80,80,0,0,0,16,104v66a14,14,0,0,0,14,14H86.67A80.15,80.15,0,0,0,160,232h66a14,14,0,0,0,14-14V152A80,80,0,0,0,169.57,72.59ZM32,104a64,64,0,1,1,64,64H32ZM224,216H160a64.14,64.14,0,0,1-55.68-32.43A79.93,79.93,0,0,0,174.7,89.71,64,64,0,0,1,224,152Z"></path>
                                </svg>
                                <div className="count">{customer.count} lượt tương tác</div>
                            </div>
                        </div>
                    </div>))}
                </div>
            )}

            </div>
        </div>
    );
};

export default TopCustomer;

import React, {useContext, useEffect, useState} from 'react';
import "./AdsInfo.sass";
import {useSelector} from "react-redux";
import { AppContext } from "../../../context/AppContext";
import getRoomAdsApi from "../../../actions/getRoomAds";
import moment from "moment";
import { Table } from 'antd';

const AdsInfo = () => {
    const room = useSelector(state => state.io.room);
    const { i18n } = useContext(AppContext);
    const [ads, setAds] = useState([]);

    useEffect(() => {

        if(room && room.lastAdReferral){
            getRoomAdsApi(room._id).then(res => {
                let ads = res.data.data;
    
                let roomAds = ads.map(ad => ({
                    key: ad._id, 
                    ad_id: ad.ad_id,
                    created_at: moment(ad.date).format('DD/MM/yyyy - HH:mm'),
                }));
    
                setAds(roomAds);
            }).catch(err => console.log(err));
        }

    }, [room])

    const columns = [
        {
            title: 'ID quảng cáo',
            dataIndex: 'ad_id',
            key: 'ad_id',
        },
        {
            title: 'Ngày tương tác',
            dataIndex: 'created_at',
            key: 'created_at',
        }
    ];

    return (
        (ads && ads.length > 0 ? (<Table size="small" pagination={false} bordered={true} columns={columns} dataSource={ads} />) : <></>)
    );
}

export default AdsInfo;

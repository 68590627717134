import axios from "axios";
import Config from "../config";

const getMessageBySession = (messageIds) => {

    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/sessions/messages",
        data: {message_ids: messageIds}
    });
};

export default getMessageBySession;

import axios from "axios";
import Config from "../config";

const getMoreMessages = ({roomID, firstMessageDate}) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/v3/message-load-more",
        data: { roomID, firstMessageDate }
    });
};

export default getMoreMessages;

import axios from "axios";
import Config from "../config";

const updateSettingMany = (settings) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/settings/update-many",
        data: {settings}
    });
};

export default updateSettingMany;

import axios from "axios";
import Config from "../config";

const getRoomLivechat = params => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/room/join-room-livechat",
        data: { username: params.username, channel_social_id: params.channel_social_id }
    });
};

export default getRoomLivechat;

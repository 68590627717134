import {Image} from "react-bootstrap";
import Config from "../config";
import striptags from "striptags";
import React from "react";
import moment from "moment";
import {FaUsers} from "react-icons/all";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

const MasterConstant = {
    convertUrls : (text) => {
        const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        return text.replace(urlRegex, function(url) {
            return '<a style="color: #0d6efd" class="message_link" href="' + url + '" target="_blank">' + url + '</a>';
        });
    },

    Picture : ({user}) => {

        const brand = cookies.get('BRAND');

        if(user.id !== 'all'){
            if (user.picture)
                return <img className="img" src={`${Config.url || ''}/api/images/${user.picture.shieldedID}/256/${brand}`} alt="chat" />;
            else
                return <div className="img">{user.firstName.substr(0,1)}{user.lastName.substr(0,1)}</div>;
        } else {
            return <div className="img"><FaUsers/></div>;

        }

    },

    contentReply : (data, length = 100) => {

        const brand = cookies.get('BRAND');

        switch (data.type) {
            case 'image':
                return (
                    <Image width="50px" height="50px"
                           src={`${Config.url || ''}/api/images/${data.content}/256/${brand}`}
                    />
                );
            case 'file':
                return (
                    <div className="content-download">
                        <div className="content-file">
                            <div className="content-name">[File] {data.file ? data.file.name : 'File'}</div>
                        </div>
                    </div>
                );
            default:
                let finalContent = data.content;

                if(finalContent){

                    let tagFullName;
                    let tagUser = finalContent.match(/@((?!@).)*-((?!@).)*@/gm);
                    if(tagUser){
                        tagUser.map(item => {

                            let tag = item.replace(/@/g, '');
                            let objTag = tag.split('-');
                            tagFullName = objTag[0];
                            finalContent = finalContent.replace(item, '<strong>@' + tagFullName + '</strong> ');
                            return finalContent;
                        });
                    }

                    let striptagContent = striptags(MasterConstant.nl2br(finalContent), []);


                    if(striptagContent.length > length){
                        finalContent = striptagContent.substr(0, length) + (striptagContent.length > length && '...');
                    }

                    return <div dangerouslySetInnerHTML={{__html: MasterConstant.convertUrls(finalContent)}} />
                }

                return <div dangerouslySetInnerHTML={{__html: MasterConstant.convertUrls(finalContent)}} />

        }
    },


    nl2br : (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },

    timeSince: (timeStamp, i18n, type = 'default') => {

        if (!(timeStamp instanceof Date)) {
            timeStamp = new Date(timeStamp);
        }

        if (isNaN(timeStamp.getDate())) {
            return "Invalid date";
        }

        var seconds = Math.floor((new Date() - timeStamp) / 1000);
    
        var interval = seconds / 31536000;
        
        switch(type){
            case 'today':
                if (interval > 1) { //Năm
                    return moment(timeStamp).format('DD/MM/yyyy - hh:mm A');
                }

                interval = seconds / 2592000;
                if (interval > 1) { //Tháng
                    return Math.floor(interval) + ` ${i18n.t('month')} ${i18n.t('ago')}`;
                }
                interval = seconds / 86400;
                if (interval > 1) { //Ngày
                return Math.floor(interval) + ` ${i18n.t('day')} ${i18n.t('ago')}`;
                }
        
                interval = seconds / 3600;
                if (interval > 1) {
                    return Math.floor(interval) + ` ${i18n.t('hour')} ${i18n.t('ago')}`;
                }
                interval = seconds / 60;
                if (interval > 1) {
                    return Math.floor(interval) + ` ${i18n.t('minute')} ${i18n.t('ago')}`;
                }

                return seconds > 30 ? Math.floor(seconds) + ` ${i18n.t('second')} ${i18n.t('ago')}` : `${i18n.t('just_done')}`;  
            default:
                if (interval > 1) {
                    return Math.floor(interval) + ` ${i18n.t('year')}`;
                }
                interval = seconds / 2592000;
                if (interval > 1) {
                    return Math.floor(interval) + ` ${i18n.t('month')}`;
                }
                interval = seconds / 86400;
                if (interval > 1) {
                return Math.floor(interval) + ` ${i18n.t('day')}`;
                }
        
        
                interval = seconds / 3600;
                if (interval > 1) {
                    return Math.floor(interval) + ` ${i18n.t('hour')}`;
                }
                interval = seconds / 60;
                if (interval > 1) {
                    return Math.floor(interval) + ` ${i18n.t('minute')}`;
                }
        
                return seconds > 0 ? Math.floor(seconds) + ` ${i18n.t('second')}` : 1 + ` ${i18n.t('second')}`;  
        }
    },

    //https://gist.github.com/valarpirai/27ed1b874142ade9b1d0
    convertDate : (timeStamp) => {
        if (!(timeStamp instanceof Date)) {
            timeStamp = new Date(timeStamp);
        }

        if (isNaN(timeStamp.getDate())) {
            return "Invalid date";
        }

        var now = new Date(),
            secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;

        var night = new Date(now.toISOString().split('T')[0]);
        var sectionToNight = (now.getTime() - night.getTime())/1000;



        var formatDate = function(date, format, utc) {
            var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var dddd = ["\x02", "Chủ nhật", "Thứ hai", "Thứ ba", "Thứ tư", "Thứ năm", "Thứ sáu", "Thứ bảy"];
            var ddd = ["\x03", "Chủ nhật", "Thứ hai", "Thứ ba", "Thứ tư", "Thứ năm", "Thứ sáu", "Thứ bảy"];

            function ii(i, len) {
                var s = i + "";
                len = len || 2;
                while (s.length < len) s = "0" + s;
                return s;
            }

            var y = utc ? date.getUTCFullYear() : date.getFullYear();
            format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
            format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
            format = format.replace(/(^|[^\\])y/g, "$1" + y);

            var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
            format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
            format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
            format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
            format = format.replace(/(^|[^\\])M/g, "$1" + M);

            var d = utc ? date.getUTCDate() : date.getDate();
            format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
            format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
            format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
            format = format.replace(/(^|[^\\])d/g, "$1" + d);

            var H = utc ? date.getUTCHours() : date.getHours();
            format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
            format = format.replace(/(^|[^\\])H/g, "$1" + H);

            var h = H > 12 ? H - 12 : H === 0 ? 12 : H;
            format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
            format = format.replace(/(^|[^\\])h/g, "$1" + h);

            var m = utc ? date.getUTCMinutes() : date.getMinutes();
            format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
            format = format.replace(/(^|[^\\])m/g, "$1" + m);

            var s = utc ? date.getUTCSeconds() : date.getSeconds();
            format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
            format = format.replace(/(^|[^\\])s/g, "$1" + s);

            var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
            format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
            f = Math.round(f / 10);
            format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
            f = Math.round(f / 10);
            format = format.replace(/(^|[^\\])f/g, "$1" + f);

            var T = H < 12 ? "AM" : "PM";
            format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
            format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

            var t = T.toLowerCase();
            format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
            format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

            var tz = -date.getTimezoneOffset();
            var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
            if (!utc) {
                tz = Math.abs(tz);
                var tzHrs = Math.floor(tz / 60);
                var tzMin = tz % 60;
                K += ii(tzHrs) + ":" + ii(tzMin);
            }
            format = format.replace(/(^|[^\\])K/g, "$1" + K);

            var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
            format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
            format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

            format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
            format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

            format = format.replace(/\\(.)/g, "$1");

            return format;
        };

        if(secondsPast < 0){ // Future date
            return timeStamp;
        }
        if(secondsPast < 60){ // Less than a minute
            return parseInt(secondsPast) + ' giây';
        }
        if(secondsPast < 3600){ // Less than an hour
            return parseInt(secondsPast/60) + ' phút';
        }

        if(secondsPast <= 86400){ // Less than a day
            if(secondsPast > sectionToNight){
                return 'Hôm qua lúc ' + formatDate(timeStamp, " h:mm tt");
            }
            return formatDate(timeStamp, "h:mm tt");
        }
        if(secondsPast <= 172800){ // Less than 2 days
            return 'Hôm qua lúc ' + formatDate(timeStamp, " h:mm tt");
        }
        if(secondsPast > 172800){ // After two days
            var timeString;

            if(secondsPast <= 604800)
                timeString = formatDate(timeStamp, "dddd") + " lúc " + formatDate(timeStamp, "h:mm tt") // with in a week
            else if(now.getFullYear() > timeStamp.getFullYear())
                timeString = formatDate(timeStamp, "MMMM d, yyyy") // a year ago
            else if(now.getMonth() > timeStamp.getMonth())
                timeString = formatDate(timeStamp, "MMMM d") // months ago
            else
                timeString = formatDate(timeStamp, "MMMM d") + " lúc " + formatDate(timeStamp, "h:mm tt") // with in a month

            return timeString;
        }
    },

    limitTitle: (title, limit = 20) => {
        return `${title.substr(0, limit)}${title.length > limit && '...'}`
    },

    getParentOrigin: () => {
        const locationAreDisctint = (window.location !== window.parent.location);
        const parentOrigin = ((locationAreDisctint ? document.referrer : document.location) || "").toString();
    
        if (parentOrigin) {
            return new URL(parentOrigin).origin;
        }
    
        const currentLocation = document.location;
    
        if (currentLocation.ancestorOrigins && currentLocation.ancestorOrigins.length) {
            return currentLocation.ancestorOrigins[0];
        }
    
        return "";
    },

    formatPrice: (price) => {
        return `${parseInt(price).toLocaleString('en-US')}`;
    },

    pushNotification: (countNoti) => {
        window.parent.postMessage({
            'func': 'showNumberNotiChatHub',
            'message': {
                number: countNoti,
            }
        }, "*");
    },

    convertTimeSelect: (selectedTime) => {
        let date = '';
        if(selectedTime === 'today'){
            date = moment().format('DD/MM/YYYY');
        }
        else if(selectedTime === 'yesterday'){
            date = moment().subtract(1, 'days').format('DD/MM/YYYY');
        }
        else if(selectedTime === '7days'){
            date = moment().subtract(7, 'days').format('DD/MM/YYYY');
        }
        else if(selectedTime === '3months'){
            date = moment().subtract(3, 'months').format('DD/MM/YYYY');
        }
        else if(selectedTime === '6months'){
            date = moment().subtract(6, 'months').format('DD/MM/YYYY');
        }
        else{ //Mặc định là 30 ngày
            date = moment().subtract(30, 'days').format('DD/MM/YYYY');
        } 

        return date;
    },

    isHttpUrl: (url) => {
        return url.startsWith('http://') || url.startsWith('https://');
    }

}

export default MasterConstant;

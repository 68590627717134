import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import "./SearchBar.sass";
import {FiSearch} from 'react-icons/fi';
import {useGlobal} from "reactn";
import getRooms from "../../../actions/getRooms";
import Actions from "../../../constants/Actions";
import useDebounce from "./useDebounce";
import { useTranslation } from 'react-i18next';

const SearchBar = ({loading, setLoading}) => {
    const { t, i18n } = useTranslation();
    const searchInput = useRef();
    const dispatch = useDispatch();

    //Filter
    const [roomFilter, setRoomFilter] = useGlobal('roomFilter');

    //Search
    const[isInput, setIsInput] = useState(false);
    const debouncedSearch = useDebounce(roomFilter.keyword, 500);

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            getRooms(roomFilter)
                .then(res => {
                    dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms});
                    setLoading(false);
                }).catch(err => console.log(err));
        }
    
        if (debouncedSearch || (isInput && debouncedSearch === '')) fetchData();

    }, [debouncedSearch]);

    const handleChangeSearchInput = (e) => {
        setIsInput(true);
        let keyword = e.target.value;

        if(keyword.length > 2 || keyword.length === 0){
            setRoomFilter({...roomFilter, keyword: keyword});
        }
    }


    return (
        <div className="search-bar uk-flex uk-flex-center uk-flex-middle">
            <div className="icon" onClick={() => searchInput.current.focus()}>
                <FiSearch/>
            </div>
            <div className="uk-inline search">
                <input className="uk-input uk-border-pill" placeholder={`${t('search_room')}...`} 
                ref={searchInput} 
                onChange={(e) => handleChangeSearchInput(e)}/>
            </div>
        </div>
    );
}

export default SearchBar;

import React, {useEffect, useState} from 'react';
import "./SearchChannel.sass";
import {
    FiFilter
} from 'react-icons/fi';
import {useGlobal} from "reactn";
import Actions from "../../../constants/Actions";
import {useDispatch, useSelector} from "react-redux";
import getChannels from "../../../actions/getChannels";
import icons from "../../../assets";
import FilterRoom from "./FilterRoom";
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const SelectChannel = () => {
    const { t } = useTranslation();
    const channels = useSelector(state => state.io.channels);
    const socialActive = useGlobal('socialActive')[0];
    const setPageCurrentRoom = useGlobal('pageCurrentRoom')[1];
    const dispatch = useDispatch();
    const [countFilter, setCountFilter] = useState(0);
    const [showDialogFilter, setShowDialogFilter] = useState(false);

    //Filter
    const [roomFilter, setRoomFilter] = useGlobal('roomFilter');
    const chatMode = useGlobal('chatMode')[0];

    useEffect(() => {
        if(chatMode === 'server'){
            getChannels(socialActive).then(res => dispatch({type: Actions.SET_CHANNELS, channels: res.data.channels})).catch(err => console.log(err));
        }
    }, [socialActive, chatMode]);

    useEffect(() => {
        countRoomFilter()
    }, [roomFilter]);

    const countRoomFilter = () => {
        let count = 0;
        if(roomFilter.linked_status !== 'all') count++;
        if(roomFilter.status !== 'all') count++;
        if(roomFilter.tag_ids.length > 0) count++;
        setCountFilter(count);
    }

    const customOption = (channel) => {
        return (
            <div className="custom-channel-name">
                <div className="avatar-channel">
                    <img src={getAvatarSocial(channel)} alt="" />
                </div>
                <span>{channel.nameApp}</span>
            </div>
        )
    }

    const getAvatarSocial = (channel) => {
        if(channel.avatar){
            return channel.avatar;
        }

        if(channel.source === 'facebook'){
            return `https://graph.facebook.com/v21.0/${channel.socialChanelId}/picture`;
        }
        
        if(channel.source === 'zalo'){
            return icons.Zalo;
        }
        
        return icons.ClientChat;
    }

    let channelLists = channels.map(channel => ({
        value: channel._id, 
        label: customOption(channel)
    }));

    channelLists.unshift({
        value: "", 
        label: customOption({nameApp: t('all_channel'), avatar: icons.Home})
    });

    const handleClickShow = () => {
        setShowDialogFilter(current => !current);
    }

    const handleChangeChannel = (channelId) => {
        setRoomFilter({...roomFilter, channel_id: channelId});
        setPageCurrentRoom(1);
    };

    return (
        <div className="select-wrap uk-flex select-bar uk-flex-between">
            <div className="select-channel">
                <Select
                    defaultValue=""
                    value={roomFilter.channel_id}
                    onChange={handleChangeChannel}
                    placeholder={`${t('choose_channel')}...`}
                    options={channelLists}
                />
            </div>
            <div className="selection-tag">
                <div className="selection-tag-icon" onClick={() => handleClickShow()}>
                    <FiFilter size={25} color={'#0067ac'}/>
                    {countFilter > 0 && <span className="filtered-count">{countFilter}</span>}
                </div>
                {showDialogFilter && <FilterRoom setShowDialogFilter={setShowDialogFilter}/>}
            </div>
        </div>
    );
}

export default SelectChannel;

import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import {Link} from "react-router-dom";
import { Table, Spin, Tabs } from 'antd';
import Config from "../../../../config";
import LineChart from "../Chart/LineChart";
import ModalDetail from "../Block/ModalDetail";
import ReportMessageTimeApi from "../../../../api/report/ReportMessageTime";
import ReportChatTotalApi from "../../../../api/report/ReportChatTotal";
import ReportDetailPageApi from "../../../../api/report/ReportDetailPage";
import ReportDetailLinkedPageApi from "../../../../api/report/ReportDetailLinkedPage";
import moment from "moment";
import {FiSlash} from 'react-icons/fi';
import icons from '../../../../assets';

const PageReport = ({selectedChannel, selectedTime}) => {
    const [overviewChart, setOverviewChart] = useState();
    const [chatTotal, setChatTotal] = useState({});
    const [detailPage, setDetailPage] = useState([]);
    const [detailPageLinked, setDetailPageLinked] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingDetail, setLoadingDetail] = useState(true);
    const [loadingLinkedDetail, setLoadingLinkedDetail] = useState(true);
    let brand = useGlobal('brand')[0];
    const parentDomain = useGlobal('parentDomain')[0];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dateRow, setDateRow] = useState('');

    useEffect(() => {
        const start_date = selectedTime[0];
        const end_date = selectedTime[1];
        ReportMessageTimeApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            getDataOverViewLineChart(data);
            setLoading(false);
        })
        .catch(err => console.log(err));

        ReportChatTotalApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            setChatTotal(data);
        })
        .catch(err => console.log(err));


        ReportDetailPageApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            setDetailPage(data);
            setLoadingDetail(false);
        })
        .catch(err => console.log(err));

        ReportDetailLinkedPageApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            setDetailPageLinked(data);
            setLoadingLinkedDetail(false);
        })
        .catch(err => console.log(err));
        
    }, [selectedTime, selectedChannel]);

    const optionsOverview = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
            tooltip: {
                callbacks: {
                    title: function(context) {
                        let index = context[0].dataIndex;
                        let dataChart = overviewChart;
                        let date = dataChart.allDates[index];
                        date = date.split('-');
                        let day = date[2];
                        let month = date[1];
                        let year = date[0];

                        return `Ngày ${day}/${month}/${year}`;
                    }
                }
            }
        },
        animation: {
            duration: 1000 // Animation duration in milliseconds
        },
        scales: {
            x: {
                grid: {
                    display: false, // Ẩn đường kẻ dọc trên trục x
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Tin nhắn' // Label mô tả cho trục x
                }
            }
        },
        maintainAspectRatio: false,
        aspectRatio: 1
    };

    const getDataOverViewLineChart = (data) => {
        const result = {
            allDates: data.allDates,
            labels: data.labels,
            datasets: [
                {
                    label: 'Số tin nhắn',
                    data: data?.values,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    tension: 0.3
                }
            ],
        };

        setOverviewChart(result);
    }

    const calcNotLinked = () => {
        let total = chatTotal?.totalRoom || 0;
        let totalLinked = chatTotal?.totalCustomerLinked?.lead + chatTotal?.totalCustomerLinked?.customer;

        let result = total - totalLinked;

        return result > 0 ? result : 0;
    }

    const getAvatar = (record) => {
        let name = record?.social_name;
        let avatar = record?.avatar;

        if(avatar){
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <img width="50" src={`${Config.url || ''}/api/images/${avatar}/256/${brand}`} alt=""/>
                    </div>
                    <Link to={`/room/${record.id}`} className="un-link">{name}</Link>
                </div>
            )
        }
        else{
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <div className="img">{name.substr(0,1).toUpperCase()}</div>
                    </div>
                    <Link to={`/room/${record.id}`} className="un-link">{name}</Link>
                </div>
            )
        }
    }

    const getIconCustomerLinked = (record) => {
        if(record.customer_linked){
            return (
                <div className="d-flex align-items-center">
                    <img className="mr-2" width="16" src={icons.Customer} alt=""/> 
                    <div>
                        <a href={`${parentDomain}/admin/customer/detail/${record.customer_linked}`} className="un-link" target="_blank" rel="noreferrer">{record.customer_name}</a> - {record.phone}
                    </div>
                </div>
            )
        }
    }

    const getIconLeadLinked = (record) => {
        if(record.lead_linked){
            return (
                <div className="d-flex align-items-center">
                    <img className="mr-2" width="16" src={icons.CpoCustomer} alt=""/> 
                    <div>
                        <a href={`${parentDomain}/customer-lead/customer-lead/detail/${record.lead_linked}`} className="un-link" target="_blank" rel="noreferrer">{record.customer_name}</a> - {record.phone}
                    </div>
                </div>
            )
        }
    }

    const getIconNotLinked = (record) => {
        if(!record.customer_linked && !record.lead_linked){
            return <span className="icon-block">{<FiSlash/>}</span>
        }
    }

    const showModal = (record) => {
        setDateRow(record.time);
        setIsModalOpen(true);
    };

    const renderDateTime = (record) => {
        return <div className='text-primary cursor-pointer' onClick={() => showModal(record)}>{record.time}</div>
    }

    const ReportDetailTable = () => {
        if(loadingDetail) return;

        const fixedColumns = [
            {
                title: 'Thời gian',
                dataIndex: 'time',
                fixed: true,
                render: (dataIndexValue, record) => renderDateTime(record)
            },
            {
                title: 'Người dùng mới',
                dataIndex: 'new_customer',
            },
            {
                title: 'Người dùng cũ tương tác lại',
                dataIndex: 'old_customer',
            },
            {
                title: 'Tin nhắn bởi người dùng',
                dataIndex: 'customer_send_message',
            },
            {
                title: 'Tin nhắn bởi trang',
                dataIndex: 'page_send_message',
            }
        ];

        let fixedData = [];
        let newCustomerTotal = 0;
        let oldCustomerTotal = 0;
        let customerSendMessageTotal = 0;
        let pageSendMessageTotal = 0;
        for (const index in detailPage) {
            fixedData.push({
                key: parseInt(index),
                time: moment(detailPage[index].date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                new_customer: detailPage[index].new_customer_count,
                old_customer: detailPage[index].old_customer_count,
                customer_send_message: detailPage[index].message_customer_count,
                page_send_message: detailPage[index].message_page_count,
            });

            newCustomerTotal += detailPage[index].new_customer_count;
            oldCustomerTotal += detailPage[index].old_customer_count;
            customerSendMessageTotal += detailPage[index].message_customer_count;
            pageSendMessageTotal += detailPage[index].message_page_count;
        }   

        return (
            <Table
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                scroll={{
                    // x: 2000,
                    y: 500,
                }}
                bordered
                summary={() => (
                    <Table.Summary fixed>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}><strong>Tổng</strong></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}><strong>{newCustomerTotal}</strong><br/> hội thoại</Table.Summary.Cell>
                        <Table.Summary.Cell index={2}><strong>{oldCustomerTotal}</strong><br/> hội thoại</Table.Summary.Cell>
                        <Table.Summary.Cell index={3}><strong>{customerSendMessageTotal}</strong><br/> tin nhắn</Table.Summary.Cell>
                        <Table.Summary.Cell index={4}><strong>{pageSendMessageTotal}</strong><br/> tin nhắn</Table.Summary.Cell>
                    </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        )
    }

    const ReportDetailLinkedTable = () => {

        let fixedColumns = [
            {
                title: 'Người dùng',
                dataIndex: 'social_name',
                fixed: true,
                render: (dataIndexValue, record) => getAvatar(record)
            },
            {
                title: 'Đã liên kết KH',
                dataIndex: 'customer_linked',
                render: (dataIndexValue, record) => getIconCustomerLinked(record)
            },
            {
                title: 'Đã liên kết KHTN',
                dataIndex: 'lead_linked',
                render: (dataIndexValue, record) => getIconLeadLinked(record)
            },
            {
                title: 'Chưa liên kết',
                dataIndex: 'not_link',
                render: (dataIndexValue, record) => getIconNotLinked(record)
            }
        ];

        let fixedData = [];
        let customerLinkedTotal = 0;
        let leadLinkedTotal = 0;
        let notLinkTotal = 0;
        for (const index in detailPageLinked) {
            fixedData.push({
                key: parseInt(index),
                id: detailPageLinked[index]?._id,
                social_name: detailPageLinked[index]?.owner?.firstName,
                customer_name: detailPageLinked[index]?.full_name,
                phone: detailPageLinked[index]?.phone,
                avatar: detailPageLinked[index]?.shieldedID,
                customer_linked: detailPageLinked[index]?.customerId || 0,
                lead_linked: detailPageLinked[index]?.cpoCustomerId || 0,
                not_link: 0,
            });

            if(detailPageLinked[index]?.customerId){
                customerLinkedTotal += 1;
            }
            
            if(detailPageLinked[index]?.cpoCustomerId){
                leadLinkedTotal += 1;
            }

            if(!detailPageLinked[index]?.customerId && !detailPageLinked[index]?.cpoCustomerId){
                notLinkTotal += 1;
            }

        }   

        return (
            <Table
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                scroll={{
                    // x: 2000,
                    y: 500,
                }}
                bordered
                summary={() => (
                    <Table.Summary fixed>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}><strong>Tổng</strong></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}><strong>{customerLinkedTotal}</strong><br/> khách hàng</Table.Summary.Cell>
                        <Table.Summary.Cell index={2}><strong>{leadLinkedTotal}</strong><br/> khách hàng</Table.Summary.Cell>
                        <Table.Summary.Cell index={3}><strong>{notLinkTotal}</strong><br/> khách hàng</Table.Summary.Cell>
                    </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        )
    }

    const itemTabs = [
        {
            key: '1',
            label: 'Theo thời gian',
            children: <Spin tip="Loading..." spinning={loadingDetail}>{loadingDetail ? <div className="loading-content"/> : <ReportDetailTable/>}</Spin>
        },
        {
            key: '2',
            label: 'Theo trạng thái liên kết',
            children: !loadingLinkedDetail && <ReportDetailLinkedTable/>,
        },
    ];

    const onChangeTab = () => {}

    return (
        <>
        <div className='activity-overview'>
            <div className="card">
                <div className="header">
                    <div className="title-desc">
                        <div className="title">Tổng quan trang</div>
                        <div className="desc">Thống kê tin nhắn của trang</div>
                    </div>
                    <div className='interaction'></div>
                </div>
                <div className="content">
                    <div className="chart-container">
                        <Spin tip="Loading..." spinning={loading}>{loading ? <div className="loading-content"/> : <LineChart options={optionsOverview} data={overviewChart}/>}</Spin>
                    </div>
                </div>
            </div>
        </div>
        <div className='view-ratio-statistic'>
            <div className="view-ratio-item">
                <img src="https://pancake.vn/static/images/statistic/page/new-phone-number.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{chatTotal?.totalRoom || 0}</span>
                    </div>
                    <div className='title-ratio'>Cuộc hội thoại mới</div>
                </div>
            </div>
            <div className="view-ratio-item">
                <img src="https://pancake.vn/static/images/statistic/page/old-customer-interact.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{chatTotal?.totalCustomerLinked?.customer || 0}</span>
                    </div>
                    <div className='title-ratio'>Đã liên kết KH</div>
                </div>
            </div>
            <div className="view-ratio-item">
                <img src="https://pancake.vn/static/images/statistic/page/new-customer.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{chatTotal?.totalCustomerLinked?.lead || 0}</span>
                    </div>
                    <div className='title-ratio'>Đã liên kết KHTN</div>
                </div>
            </div>
            <div className="view-ratio-item">
                <img src="https://pancake.vn/static/images/statistic/page/new-inbox-count.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{calcNotLinked()}</span>
                    </div>
                    <div className='title-ratio'>Chưa liên kết</div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Thống kê chi tiết</div>
                    <div className="desc">Thống kê chi tiết lượt tương tác đến trang của bạn</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content">
                <Tabs defaultActiveKey="1" items={itemTabs} onChange={onChangeTab} />
            </div>
        </div>
        {isModalOpen && <ModalDetail selectedChannel={selectedChannel} dateRow={dateRow} isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)}/>}
        </>
    );
};

export default PageReport;

import axios from "axios";
import Config from "../config";

const closeSession = data => {
    const { room_id } = data;

    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/sessions/close",
        data: { room_id }
    });
};

export default closeSession;

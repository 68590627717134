import React, {useState, useEffect, useContext} from "react";
import './Room.sass';
import './ButtonDirect.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomerInfo from "./CustomerInfo";
import RoomFeature from "./RoomFeature";
import {useSelector} from "react-redux";
import {useGlobal} from "reactn";
import SweetAlert from "react-bootstrap-sweetalert";
import { AppContext } from '../../../context/AppContext';
import detectCustomer from "../../../actions/detectCustomer";
import Actions from "../../../constants/Actions";
import store from "../../../store";

const RoomInfo = () => {
    const room = useSelector(state => state.io.room);
    const { i18n } = useContext(AppContext);
    const [showAlert, setShowAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingCustomerInfo, setLoadingCustomerInfo] = useState(false);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [isDetectCustomer, setIsDetectCustomer] = useState(false);
    const [customerName, setCustomerName] = useState('');

    const setShowPipeline = useGlobal('showPipeline')[1];

    let userChat = {
        firstName: 'A', lastName: 'A'
    };

    userChat = room.owner;

    useEffect(() => {
        detectCustomerAction(userChat._id);
    }, [userChat]);

    const detectCustomerAction = (user_id) => {
        // handleSetLoading(true);
        setLoadingCustomerInfo(true);
        detectCustomer({user_id}).then(res => {
            setLoading(false);
            if(res.data.error === 0 && res.data.data){
                let data = res.data.data;

                if(data.customer_id || data.customer_lead_id){
                    let customer = data;
                    userChat.mapping_id = data.mapping_id;
                    userChat.customer_id = customer.customer_id;
                    userChat.customer_lead_id = customer.customer_lead_id;
                    userChat.phone = customer.phone;
                    userChat.point = customer.point ?? 0;
                    userChat.level = customer.level ?? '';
                    userChat.code = customer.customer_code;
                    userChat.lead_code = customer.customer_lead_code;
                    userChat.email = customer.email;
                    userChat.address = customer.full_address || customer.address;
                    userChat.type = customer.type;
                    userChat.related_user = customer.related_user;
                    userChat.journey_tracking = customer.journey_tracking;
                    userChat.journey_edit = customer.journey_edit;
                    userChat.journey_code = customer.journey_code;
                    userChat.pipeline_name = customer.pipeline_name;
                    setCustomerInfo(userChat);
                    setIsDetectCustomer(true);
                    setCustomerName(customer.full_name);
                    setShowPipeline(true);
                }
                else{
                    userChat.mapping_id = data.mapping_id;
                    setCustomerInfo(userChat);
                    setCustomerName(userChat.firstName);
                    setIsDetectCustomer(false);
                    setShowPipeline(false);
                }

                store.dispatch({type: Actions.SET_CUSTOMER, customer: userChat})
            }
            else{
                setShowPipeline(false);
                setCustomerName(userChat.firstName);
                setIsDetectCustomer(false);
                store.dispatch({type: Actions.SET_CUSTOMER, customer: {}})
            }

            setLoadingCustomerInfo(false);
        }).catch(err => {
            setLoadingCustomerInfo(false);
            console.log(err);
        });
    }

    // Hiển thị thông báo
    const showAlertAction = (type = 'success',message, title = '') => {
        setShowAlert(<SweetAlert 
            type={type}
            title={title} 
            btnSize="sm" 
            confirmBtnText={i18n.t('confirm')} 
            // confirmBtnBsStyle="warning" 
            onConfirm={() => hideAlertAction()} 
            onCancel={() => hideAlertAction()}>
                {message}
            </SweetAlert>
        )
    }

    const hideAlertAction = () => {
        setLoading(false);
        setShowAlert(null);
    }

    return (
        <>
            {showAlert}
            <div className="details-room">
                <CustomerInfo 
                    userChat={userChat}
                    setShowAlert={setShowAlert}
                    showAlertAction={showAlertAction}
                    hideAlertAction={hideAlertAction}
                    detectCustomerAction={detectCustomerAction}
                    loadingCustomerInfo={loadingCustomerInfo}
                    customerInfo={customerInfo}
                    isDetectCustomer={isDetectCustomer}
                    customerName={customerName}
                    setCustomerName={setCustomerName}
                />
                <RoomFeature 
                    userChat={userChat}
                    setShowAlert={setShowAlert}
                    showAlertAction={showAlertAction}
                    hideAlertAction={hideAlertAction}
                    detectCustomerAction={detectCustomerAction}
                    loadingCustomerInfo={loadingCustomerInfo}
                    customerInfo={customerInfo}
                    isDetectCustomer={isDetectCustomer}
                    customerName={customerName}
                />
            </div>
        </>
    );
};

export default RoomInfo;

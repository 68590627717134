import axios from "axios";
import Config from "../config";

const changeStatusChatbotAll = (data) => {
    let {status = 0} = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/chatbot/change-status-all",
        data: {status}
    });
};

export default changeStatusChatbotAll;

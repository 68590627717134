import axios from "axios";
import Config from "../config";

const getSession = (room_id, offset, limit) => {

    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/sessions",
        data: {room_id, offset, limit}
    });
};

export default getSession;

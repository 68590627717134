import axios from "axios";
import Config from "../config";

const updateUserInfo = (user_id, params) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/user/update-info",
        data: {user_id, params}
    });
};

export default updateUserInfo;

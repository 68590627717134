import axios from "axios";
import Config from "../config";

const getSetting = () => {
    return axios({
        method: "GET",
        url: (Config.url || '') + "/api/settings",
        data: {}
    });
};

export default getSetting;

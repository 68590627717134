import axios from "axios";
import Config from "../config";

const updateChatLivechat = room_id => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/room/update-chat-livechat",
        data: { room_id }
    });
};

export default updateChatLivechat;

import axios from "axios";
import Config from "../config";

const changeStatusChatbot = (data) => {
    let {room_id, status = 0} = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/chatbot/change-status",
        data: {room_id, status}
    });
};

export default changeStatusChatbot;

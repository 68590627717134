import React, {useEffect, useState, useContext} from 'react';
import {useGlobal} from "reactn";
import getChannels from "../../../actions/getChannels";
import changeStatusChatbotAllApi from "../../../actions/changeStatusChatbotAll";
import checkStatusChatbotRoomApi from "../../../actions/checkStatusChatbotRoom";
import updateSettingManyApi from "../../../actions/updateSettingMany";
import getSettingApi from "../../../actions/getSetting";
import { FiAlertCircle, FiRefreshCcw} from "react-icons/fi";
import './SettingModal.sass';
import { AppContext } from "../../../context/AppContext";
import syncMessageAction from "../../../actions/syncMessage";
import getRooms from "../../../actions/getRooms";
import Actions from "../../../constants/Actions";
import store from "../../../store";
import { Space, Tooltip, Switch, Modal, Tabs, Select, InputNumber, Input, Form, Button } from 'antd';
const { TextArea } = Input;

const SettingModal = React.memo(({showModalSetting, setShowModalSetting}) => {
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [channelLists, setChannelLists] = useState("");
    const [checkedChatbot, setCheckedChatbot] = useState(false);
    const { showAlertAction, isLoading, setIsLoading, i18n } = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    //Filter
    const roomFilter = useGlobal('roomFilter')[0];

    //Config
    const [Setting, setSetting] = useGlobal('settings');
    const [form] = Form.useForm();

    useEffect(() => {
        fetchSettings();

        getChannels().then(res => {
            let channels = res.data.channels;
            let channelLists = channels.map(channel => ({value: channel._id, label: channel.nameApp}));
            setChannelLists(channelLists);
        })
        .catch(err => console.log(err));

        checkStatusChatbotRoomApi().then(res => {
            let response = res.data.data;
            if(response.has_enable){
                setCheckedChatbot(true);
            }
            else{
                setCheckedChatbot(false);
            }
        })
        .catch(err => console.log(err));
    }, []);

    const fetchSettings = async () => {
        setLoading(true);

        try {
            // Giả sử API trả về dữ liệu như thế này
            const res = await getSettingApi();
            const response = res.data.data;
            setSetting(response);

            form.setFieldsValue({
                client_welcome_message: response.client_welcome_message,
            });

            form.setFieldsValue({
                chat_session_expired: response.chat_session_expired,
            });
        
            // Điền giá trị vào form
            form.setFieldsValue({
                client_welcome_message: response.client_welcome_message
            });
        } catch (error) {
            console.log('Lỗi khi tải cài đặt');
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStatus = (selected) => {
        setSelectedChannel(selected);
    };

    const reloadRooms = () => {
        getRooms(roomFilter)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    const syncMessage = () => {
        if(isLoading) return;

        if(!selectedChannel){
            setShowModalSetting(false);
            showAlertAction('warning', i18n.t('not_select_channel'));
            return;
        }

        setIsLoading(true);
        syncMessageAction(selectedChannel.value).then(res => {
            if(res.data.error === 0){
                setIsLoading(false);
                setShowModalSetting(false);
                showAlertAction('success', i18n.t('sync_message_success'));
                reloadRooms();
            }
            else{
                setIsLoading(false);
                setShowModalSetting(false);
                showAlertAction('warning', i18n.t('no_message_sync'))
            }
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false);
            setShowModalSetting(false);
            showAlertAction('warning', i18n.t('sync_message_failed'))
        });
    }

    //Cấu hình bật/tắt Chatbot cho tất cả cuộc trò chuyện
    const onChangeChatbot = (checked) => {
        let status = checked ? 1 : 0;

        changeStatusChatbotAllApi({status}).then((res) => {
            let response = res.data;
            if(!response.error){
                setCheckedChatbot(status ? true : false);
                showAlertAction('success', response.message);
                reloadRooms();
            }
        }).catch(err => console.log('changeStatusChatbotAllApi', err));
    }

    // Component Cài đặt chung
    const SettingGeneralTab = () => {
        return (
            <div className="config-wrap">
                <div className="config-item">
                    <Space align="center">
                        <Tooltip placement="right" title={'Đồng bộ tin nhắn mới từ kênh'}>
                            <span>{i18n.t('sync_message')} </span>
                            <FiAlertCircle size={15}/>
                        </Tooltip>
                        <Select
                                value={selectedChannel}
                                onChange={handleChangeStatus}
                                placeholder={`${i18n.t('choose_channel')}...`}
                                options={channelLists}
                            />
                        <div className={`btn btn-primary ${isLoading ? 'prevent-events' : ''}`} onClick={() => syncMessage(false)}><FiRefreshCcw className={isLoading ? 'rorate' : ''} /> {isLoading ? `${i18n.t('synchronizing')}...` : i18n.t('sync')}</div>
                    </Space>
                </div>
                <div className="config-item">
                    <Space align="center">
                        <Tooltip placement="right" title={'Thời gian tự động kết thúc phiên kể từ tin nhắn cuối cùng'}>
                            <span>Thời gian kết thúc phiên </span>
                            <FiAlertCircle size={15}/>
                        </Tooltip>
                        <Form.Item name="chat_session_expired">
                            <InputNumber min={0} max={3600} defaultValue={0} />
                        </Form.Item>
                        <span>phút</span>
                    </Space>
                </div>
            </div>
        )
    }

    // Component Cấu hình Chatbot
    const SettingChatbotTab = () => {
        return (
            <div className="config-wrap">
                {Setting && Setting.chatbot_enable ? 
                    <>
                    <div className="config-item">
                        <Space align="center">
                            <Tooltip placement="right" title={'Bật/Tắt Chatbot tất cả các cuộc trò chuyện'}>
                                <span>Bật/Tắt Chatbot </span>
                                <FiAlertCircle size={15}/>
                            </Tooltip>
                            <Switch onChange={onChangeChatbot} checked={checkedChatbot}/>
                        </Space>
                    </div>
                    {/* <div className="config-item">
                        <Space align="center">
                            <Tooltip placement="right" title={'Khi có cuộc trò chuyện mới, Chatbot sẽ tự động được bật cho cuộc trò chuyện đó'}>
                                <span>Bật Chatbot tự động </span>
                                <FiAlertCircle size={15}/>
                            </Tooltip>
                            <div className="config-item-right">
                                <Switch onChange={onChangeChatbotAuto} checked={Setting.auto_enable_chatbot}/>
                            </div>
                        </Space>
                    </div> */}
                    </>
                : <></>}
            </div>
        )
    }

    // Component Cấu hình Chatbot
    const SettingLivechatTab = () => {
        return (
            <div className="config-wrap">
                <div className="config-item">
                    <Space align="center">
                        <Tooltip placement="right" title={'Tin nhắn chào đầu tiên trong chat Livechat. Sử dụng {{channel_name}} để hiển thị tên kênh chat'}>
                            <span>Tin nhắn chào </span>
                            <FiAlertCircle size={15}/>
                        </Tooltip>
                        <Form.Item name="client_welcome_message">
                            <TextArea
                                placeholder="Tin nhắn chào đầu tiên trong chat Livechat. Sử dụng {{channel_name}} để hiển thị tên kênh chat"
                                autoSize={{
                                    minRows: 2,
                                    maxRows: 6,
                                }}
                            />
                        </Form.Item>
                    </Space>
                </div>
            </div>
        )
    }

    const getTabItems = () => {
        let tabItems = [
            {
                label: i18n.t('general_config'),
                key: 'general_config',
                children: <SettingGeneralTab/>,
            }
        ];
        
        if(Setting && Setting.chatbot_enable){
            tabItems.push({
                label: 'Chatbot',
                key: 'chatbot',
                children: <SettingChatbotTab/>,
            });
        }

        // tabItems.push({
        //     label: 'Livechat',
        //     key: 'livechat',
        //     children: <SettingLivechatTab/>,
        // });

        return tabItems;
    }

    const onSubmitSetting = (values) => {
        updateSettingManyApi(values).then((res) => {
            let result = res.data;
            if(!result.error){
                showAlertAction('success', result.message);
            }
        }).catch(err => console.log('updateSettingApi', err));
    }

    return (
        <Modal
            open={showModalSetting}
            id="modal-setting"
            title={i18n.t('config')}
            onCancel={() => setShowModalSetting(false)}
            width={1000}
            footer={null}
            confirmLoading={loading}
            centered>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmitSetting} // Khi submit form
                    >
                    <Tabs items={getTabItems()} />
                    <Form.Item className='text-right m-0'>
                        <Button onClick={() => setShowModalSetting(false)} style={{ marginRight: '10px' }}>Đóng</Button>
                        <Button type="primary" htmlType="submit" style={{ marginTop: '10px' }}>Cập nhật</Button>
                    </Form.Item>
                </Form>
        </Modal>
    );
});

export default SettingModal;

import axios from "axios";
import Config from "../config";

const registerLivechat = (data) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/livechat/register-livechat",
        data: {
            user_id: data.user_id, 
            full_name: data.full_name, 
            phone: data.phone, 
            room_id: data.room_id,
            channel_id: data.channel_id
        },
    });
};

export default registerLivechat;
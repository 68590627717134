import React, {useEffect, useState, useContext} from 'react';
import {useGlobal} from "reactn";
import {useSelector, useDispatch} from "react-redux";
import updateUserInfoApi from "../../../actions/updateUserInfo";
import './EditUserModal.sass';
import { AppContext } from "../../../context/AppContext";
import { Modal, Input, Form, Button } from 'antd';
import getRooms from "../../../actions/getRooms";
import getRoom from "../../../actions/getRoom";
import store from "../../../store";
import Actions from "../../../constants/Actions";

const EditUserModal = React.memo(({showModal, setShowModal, setCustomerName, userChat}) => {
    const { showAlertAction, i18n } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const room = useSelector(state => state.io.room);
    const dispatch = useDispatch();

    //Filter
    const roomFilter = useGlobal('roomFilter')[0];

    //Filter
    const [form] = Form.useForm();

    useEffect(() => {
        room && fetchUserInfo();
    }, [room]);

    const fetchUserInfo = () => {
        try {
            form.setFieldsValue({
                firstName: room?.owner?.firstName || '',
            });

            form.setFieldsValue({
                phone: room?.owner?.phone || '',
            });
        } catch (error) {
            console.log('Lỗi khi lấy thông tin người dùng');
        }
    };

    const onSubmit = (values) => {
        let userId = room?.owner?._id;

        if(userId){
            updateUserInfoApi(userId, values).then((res) => {
                let result = res.data;
                if(!result.error){
                    showAlertAction('success', result.message);

                    //Reload lại room hiện tại
                    reloadCurrentRoom();

                    //Reload lại danh sách room
                    reloadRooms();
                }
            }).catch(err => console.log('updateUserInfoApi', err));

            setShowModal(false);
        }
    }

    const reloadCurrentRoom = () => {
        getRoom(room._id).then(res => {
            let dataRoom = res.data.room;
            dispatch({type: Actions.SET_ROOM, room: dataRoom});
            dispatch({type: Actions.SET_MESSAGES, messages: dataRoom.messages});
            dispatch({type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: room._id});
        }).catch(err => console.log(err));
    }

    const reloadRooms = () => {
        getRooms(roomFilter)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    return (
        <Modal
            open={showModal}
            id="modal-edit-user"
            title={i18n.t('update_user')}
            onCancel={() => setShowModal(false)}
            width={500}
            footer={null}
            confirmLoading={loading}
            centered>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit} //Khi submit form
                    >
                    <Form.Item label={i18n.t('user_name')} name="firstName" rules={[{ required: true, message: i18n.t('please_enter_user_name') }]}>
                        <Input placeholder={i18n.t('user_name')} />
                    </Form.Item>
                    <Form.Item label={i18n.t('phone')} name="phone" rules={[
                        {required: false, message: i18n.t('please_enter_phone')},
                        {pattern: /^[0-9]{9,10}$/, message: 'Số điện thoại không hợp lệ!'}
                    ]}>
                        <Input placeholder={i18n.t('phone')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '10px', textAlign: 'center' }}>
                        <Button className="mt-2" type="primary" loading={loading} htmlType="submit">{i18n.t('save')}</Button>
                    </Form.Item>
                </Form>
        </Modal>
    );
});

export default EditUserModal;

import React, {useState, useRef, useEffect, useContext, useCallback} from 'react';
import Message from "./Message";
import { Lightbox } from "react-modal-image";
import Config from "../../../config";
import {useGlobal} from "reactn";
import './Messages.sass';
import {FiEyeOff, FiEye, FiAlertCircle} from "react-icons/fi";
import {Image} from "react-bootstrap";
import PipelineSlide from "../../Details/components/PipelineSlide";
import {useDispatch, useSelector} from "react-redux";
import getMoreMessages from "../../../actions/getMoreMessages";
import getListTagByUserApi from "../../../actions/getListTagByUser";
import updateChatLivechatApi from "../../../actions/updateChatLivechat";
import Actions from "../../../constants/Actions";
import store from "../../../store";
import LiveChatFormStarted from "./LiveChatFormStarted";
import { AppContext } from "../../../context/AppContext";
import MasterConstant from "../../../constants/MasterConstant";

const Messages = ({canSend}) => {
    const user = useGlobal('user')[0];
    const brand = useGlobal('brand')[0];
    const messages = useSelector(state => state.io.messages);
    const room = useSelector(state => state.io.room);
    const chatMode = useGlobal('chatMode')[0];

    const setUserSelectedTagGlobal = useGlobal('userSelectedTag')[1];
    const [userActiveTag, setUserActiveTagGlobal] = useGlobal('userActiveTag');
    const showPipeline = useGlobal('showPipeline')[0];
    const [loading, setLoading] = useState(false);
    const typing = useSelector(state => state.messages.typing);
    const [loadingTag, setLoadingTag] = useState(false);
    const [showListTag, setShowListTag] = useState(true);
    const [heightTagList, setHeighTagList] = useState(0);
    const { i18n } = useContext(AppContext);

    const livechatColor = useGlobal('livechatColor')[0];

    const chat = useRef(null);
    const [open, setOpen] = useState(null);

    useEffect(() => {
        if (chat.current){
            keepScrollAtBottom()
        };
    }, [messages]);

    useEffect(() => {
        if(chatMode === 'client'){
            window.addEventListener('message', function(event) {
                console.log('mess')
                if (event.data.type === 'LIVE_CHAT_READ_MESSAGE') {
                    console.log('LIVE_CHAT_READ_MESSAGE');

                    updateChatLivechatApi(room._id)
                    .then(res => {})
                    .catch(err => console.log('updateChatLivechatApi', err));
                }
            });
        }
    }, [chatMode, room]);

    useEffect(() => {
        setUserActiveTagGlobal([]);
        //Reset customer global
        store.dispatch({type: Actions.SET_CUSTOMER, customer: {}});
    }, []);

    useEffect(() => {
        if (typing && chat.current) keepScrollAtBottom();
    }, [typing])

    let userChat = {
        firstName: 'A', lastName: 'A'
    };

    if (!room.isGroup && room.owner) {
        userChat = room.owner;
    }

    if ( typing !== false && room && room.isGroup && room.owner) {
        userChat = room.owner;
    }

    useEffect(() => {
        if(userChat && chatMode === 'server'){
            getListTagByUser(); 
        }
    }, [room]);

    const getListTagByUser = async () => {
        setLoadingTag(true);
        getListTagByUserApi({user_id: userChat._id}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setUserSelectedTagGlobal(result);
                const listTagShow = result && result.filter(e => e.isActive === true);
                setUserActiveTagGlobal(listTagShow);

                setLoadingTag(false);

                //set padding-top message
                const tagListElement = document.getElementsByClassName('tags-wrapper');

                if(tagListElement[0]){
                    let heightTag = tagListElement[0].clientHeight;
                    if(heightTag){
                        setHeighTagList(heightTag);
                    }
                }
                
            }
        }).catch(err => console.log('getListTagApi', err));
    }

    const Messages = messages.map((message, index) => {
        let messageData = [];

        if(room.messageSeen){
            room.messageSeen.forEach((messageSeen, index)=>{
                if(messageSeen.message === message._id){
                    messageData.push(messageSeen);
                }
            });
        }
        
        return <Message
            key={message._id}
            message={message}
            previous={messages[index - 1]}
            next={messages[index + 1]}
            onOpen={setOpen}
            messageSeen={messageData}
            room={room}
        />;
    });

    const keepScrollAtBottom = () => {
        chat.current.scrollTop = chat.current.scrollHeight;
    };

    const onScroll = () => {
        if (chat.current.scrollTop > 0) {
            return;
        }

        if (loading) return;
        setLoading(true);

        // Lưu vị trí cuộn hiện tại và offset
        const currentScrollTop = chat.current.scrollTop;
        const currentOffset = chat.current.scrollHeight - currentScrollTop;

        getMoreMessages({roomID: room._id, firstMessageDate: messages[0].date}).then(res => {
            let messages = res.data.messages;
            if(messages.length){
                store.dispatch({type: Actions.MORE_MESSAGES, messages: res.data.messages});
                setLoading(false);
                
                // Tính toán vị trí cuộn mới
                const newScrollTop = chat.current.scrollHeight - currentOffset;
                // Cập nhật vị trí cuộn
                chat.current.scrollTop = newScrollTop;
            }
            else{
                chat.current.scrollTop = 1;
            }

        }).catch(err => {
            setLoading(false);
        });
    };

    const handleShowListTag = () => {
        setShowListTag(e => !e);
    }

    const AvatarBot = (typing) => {
        const staffBot = room.staff_bot;
        const channel = room.channel;

        if(typing.type === 'client'){
            if(channel?.avatar){
                return <img className="img" src={channel.avatar} alt="Channel Bot Chat"/>;
            }
            else if(channel?.nameApp){
                return <div className="img">{channel.nameApp.substr(0,1).toUpperCase()}</div>;
            }
        }
        else{
            if(staffBot?.staff_avatar){
                return <img className="img" src={staffBot.staff_avatar} alt="Admin Bot Chat"/>;
            }
            else if(staffBot?.full_name){
                return <div className="img">{staffBot.full_name.substr(0,1).toUpperCase()}</div>;
            }
        }
        
        return <></>
        
    };

    const Typing = (typing) => {
        const channel = room.channel;
        let botName = 'Chatbot';
        let classNameBot = 'name_user_right';
        let alignClass = 'right';

        if(room?.staff_bot?.full_name){
            botName = room.staff_bot.full_nam;
        }

        if(typing?.type === 'client'){
            classNameBot = 'name_user_left';
            alignClass = 'left';
            botName = channel.nameApp;
        }

        return (
            <div className="message-item">
                <div className={`message ${alignClass} attach-previous`}>
                    <div className="message-main">
                        <div className="picture">
                            <AvatarBot {...typing}/>
                        </div>
                        <div className={`content-x ${alignClass}`}>
                            <span className={classNameBot}>{botName}</span>
                            <div className={`bubble ${typing.type === 'chathub' ? 'bubble-right right' : 'bubble-left left'}`}>
                                <div id="wave">
                                    <span className="dot" style={{ backgroundColor: livechatColor }}/>
                                    <span className="dot" style={{ backgroundColor: livechatColor }}/>
                                    <span className="dot" style={{ backgroundColor: livechatColor }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const BlockMessageWelcome = () => {
        let messageContent = '';

        if(room && room.channel?.enable_greeting && room.channel?.greeting){
            messageContent = room.channel.greeting;
        }

        if(messageContent === ''){
            return <></>;
        }
        

        return (
            <div className="message-item">
                <div className="message left">
                    <div className="message-main">
                        <div className="content-x mt-2 left">
                            <div className="message-content bubble bubble-left left">
                                <div>{messageContent}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ImageLightbox = (message) => {
        let imageUrl = '';
        if(MasterConstant.isHttpUrl(message.content)){
            imageUrl = message.content;
        }else{
            imageUrl = `${Config.url || ''}/api/images/${open.content}/1024/${brand}`;
        }

        return (<Lightbox
            medium={imageUrl}
            alt="Lightbox"
            hideDownload={true}
            onClose={() => setOpen(null)}
        />);
    }

    return (
        <>
        {room?.channel?.is_lost_connection === 1 && (<div className="chat-alert">
            <FiAlertCircle size="20" color={'#dd2c00'}/>
            <span>Trang <b>{room.channel.nameApp}</b> đã bị mất kết nối. Vui lòng kết nối lại Trang/OA</span>
        </div>)}
        {!canSend && (<div className="chat-danger">
            <FiAlertCircle size="20" color={'#dd2c00'}/>
            <span>
            {i18n.t('oa_warning_7_days')} <a href="https://oa.zalo.me/home/resources/news/thong-bao-chinh-sach-gui-tin-va-quy-dinh-phi-gui-tin_1433049880779375099" target="_blank" rel="noreferrer">
                    {i18n.t('more_detail')}
                </a>
            </span>
        </div>)}
        {(!loadingTag && userActiveTag && userActiveTag.length > 0 && chatMode !== 'client') && (
            <div className="tags-wrapper">
                {showListTag && (
                    <div className="lists">
                        {userActiveTag.map(tag => <div key={tag._id} className="user-label-tag" style={{ background: tag.color }}>{tag.name}</div>)}
                    </div>
                )}
                <div className="icon" onClick={() => handleShowListTag()}>{showListTag ? <FiEyeOff/> : <FiEye/>}</div>
            </div>
        )}
        <div className={`messages-wrapper messages-wrapper-fix ${showPipeline ? 'has-pipeline' : ''}`} 
            ref={chat} onScroll={onScroll} 
        >
            {showPipeline && <PipelineSlide/>}
            <div className="messages-container">
                {open && <ImageLightbox {...open}/>}
                {user && room && user.firstName.includes('Guest') && chatMode === 'client'
                && <>
                    {!room.lastMessage && <BlockMessageWelcome/>}
                    {room.channel?.show_form_livechat === 1 && <LiveChatFormStarted/>}                    
                </>}
                {Messages}
                {typing?.isTyping && <Typing {...typing}/>}
            </div>
        </div>
        </>
    );
};

export default Messages;

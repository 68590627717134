import axios from "axios";
import Config from "../config";

const authenLivechat = (brand, data) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/livechat/config-livechat",
        data: {uuid: data.uuid, social_channel_id: data.channel},
        headers: {
            'brand-code': brand || 'sale'
        }
    });
};

export default authenLivechat;
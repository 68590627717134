import React, {useState, useEffect, useContext} from "react";
import './SessionDetailModal.sass';
import {useSelector} from "react-redux";
import { AppContext } from '../../../context/AppContext';
import getMessageBySessionApi from "../../../actions/getMessageBySession";
import summaryConversationApi from "../../../actions/summaryConversation";
import Message from "../../Conversation/components/Message";
import { Card, Modal, Button, Tag, Tabs, Spin, Alert } from 'antd';
import {useGlobal} from "reactn";
import moment from "moment";
import {
    FullscreenExitOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

const SessionDetailModal = ({room, showModal, setShowModal}) => {
    const { i18n } = useContext(AppContext);
    const [messageSessions, setMessageSessions] = useState([]);
    const selectedSessionItem = useGlobal('selectedSessionItem')[0];
    const settings = useGlobal('settings')[0];
    const [summaryContent, setSummaryContent] = useState("");
    const [countMessage, setCountMessage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingSummary, setLoadingSummary] = useState(false);
    const [open, setOpen] = useState(null);

    useEffect(() => {
        getMessageFromSession();
    }, []);

    const getMessageFromSession = () => {
        if(!selectedSessionItem) return;

        setLoading(true);
        const messageIds = selectedSessionItem.messages;

        setCountMessage(messageIds.length);

        getMessageBySessionApi(messageIds).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setMessageSessions(result); 
                setSummaryContent(selectedSessionItem.summary || "Phiên chat chưa có nội dung tóm tắt");
                setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const handleSessionSummary = () => {
        setLoadingSummary(true);

        summaryConversationApi(selectedSessionItem._id).then((res) => {
            let result = res.data;
            if(!result.error){
                setSummaryContent(result.data?.summary || "");
            }

            setLoadingSummary(false);
        }).catch(err => {
            console.log('summaryConversationApi', err);
            setLoadingSummary(false);
        });
    }


    const Messages = messageSessions && messageSessions.map((message, index) => {
        let messageData = [];

        if(room.messageSeen){
            room.messageSeen.forEach((messageSeen, index)=>{
                if(messageSeen.message === message._id){
                    messageData.push(messageSeen);
                }
            });
        }
        
        return <Message
            key={message._id}
            message={message}
            previous={messageSessions[index - 1]}
            next={messageSessions[index + 1]}
            onOpen={setOpen}
            messageSeen={messageData}
            room={room}
        />;
    });

    const MessageTab = () => {
        return (
            <div className="messages-wrapper">
                <div className="messages-container">
                    {Messages}
                </div>
            </div>
        )
    }

    const SummaryTab = () => {
        return (
            <div className="session-summary">
                <div className="session-summary--action">
                    <Button type="primary" loading={loadingSummary} icon={<FullscreenExitOutlined />} onClick={() => handleSessionSummary()}>{i18n.t('session_summary')}</Button>
                </div>
                <div className="session-summary--content">
                    {/* <Card size="small" title="Nội dung tóm tắt phiên chat">
                    
                    </Card> */}
                    {loadingSummary 
                        ? <Spin tip="Loading..."><Alert
                            message=""
                            description="Hệ thống đang tóm tắt nội dung phiên chat này."
                            type="info"
                        /></Spin> 
                        : <Tag color="default" bordered={false}><div dangerouslySetInnerHTML={{ __html: summaryContent }}></div></Tag>
                    }
                </div>
            </div>
        )
    }

    const getTabItems = () => {
        let tabItems = [
            {
                label: `${i18n.t('messages_session')} (${countMessage})`,
                key: 'message_session',
                children: <MessageTab/>,
            }
        ];

        if(settings && settings.enable_summary === 1){
            tabItems.unshift({
                label: i18n.t('summary'),
                key: 'summary',
                children: <SummaryTab/>,
            });
        }

        return tabItems;
    }

    return (
        <Modal
            open={showModal}
            id="modal-setting"
            title={i18n.t('session_chat_infomation')}
            onCancel={() => setShowModal(false)}
            width={1000}
            footer={null}
            confirmLoading={loading}
            centered>
                <div className="session-detail">
                    <Tabs items={getTabItems()} />
                </div>
        </Modal>
    )
};

export default SessionDetailModal;

import axios from "axios";
import Config from "../config";

const getRooms = (params) => {
    const {
        keyword = '', 
        source = null, 
        channel_id = null, 
        status = 'all', 
        tag_ids = [], 
        linked_status = 'all',
        page = 1
    } = params;

    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/v3/list-rooms",
        data: { page, keyword, source, channel_id, status, tag_ids, linked_status }
    });
};

export default getRooms;

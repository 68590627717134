import React, {useRef, useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import BottomBarNotSend from "./components/BottomBarNotSend";
import "./Conversation.sass";
import {useParams} from "react-router-dom";
import getRoom from "../../actions/getRoom";
import getRoomByUser from "../../actions/getRoomByUser";
import getRoomLivechat from "../../actions/getRoomLivechat";
import ClipLoader from "react-spinners/ClipLoader";
import Messages from "./components/Messages";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../constants/Actions";
import ModalForward from "./components/ModalForward";
import getRooms from "../../actions/getRooms";
import getQuotaMessageApi from "../../actions/getQuotaMessage";
import store from "../../store";

const Conversation = () => {
    const room = useSelector(state => state.io.room);
    const user = useGlobal('user')[0];
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const setOver = useGlobal('over')[1];
    const {id, uid} = useParams();
    const [canSend, setCanSend] = useState(true);
    const [onSendMessTrans, setSendMessTrans] = useGlobal('onSendMessTrans');
    
    const history = useHistory();
    const dispatch = useDispatch();

    const chatMode = useGlobal('chatMode')[0];

    //Filter
    const roomFilter = useGlobal('roomFilter')[0];

    //Config
    const settings = useGlobal('settings')[0];

    useEffect(() => {
        if (room && room.owner && room.channel.source === 'zalo'){
            getQuotaMessage()
        }else{
            setCanSend(true);
        }
    }, [room]);

    const back = () => {
        setOver(false);
        history.replace('/');
    }

    const getQuotaMessage = () => {
        getQuotaMessageApi({user_social_id: room.owner.userSocialId, social_channel_id: room.channel.socialChanelId})
            .then(res => {
                if(res.data.error === 0 && res.data.data){
                    let result = res.data.data;
                    setCanSend(result.can_send);
                    setSendMessTrans(result.on_message_transaction ? true : false);
                }
            }).catch(err => console.log('getQuotaMessageApi', err));
    }

    useEffect(() => {
        setLoading(true);
        if(id){ //Lấy room dựa theo roomId
            getRoom(id).then(res => {
                let dataRoom = res.data.room;
                let userChat = dataRoom.owner;
                sessionStorage.setItem('user_chat', JSON.stringify(userChat));
                dispatch({type: Actions.SET_ROOM, room: dataRoom});
                dispatch({type: Actions.SET_MESSAGES, messages: dataRoom.messages});
                setLoading(false);
                setError(false);
                dispatch({type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: id});
            }).catch(err => {
                dispatch({type: Actions.SET_ROOM, room: null});
                dispatch({type: Actions.SET_MESSAGES, messages: []});
                setLoading(false);
                if (!err.response || err.response.status !== 404) setError(true);
            });
        }else if(uid){ //Lấy room dựa theo userId
            getRoomByUser(uid).then(res => {
                let dataRoom = res.data.room;
                let userChat = dataRoom.owner;
                sessionStorage.setItem('user_chat', JSON.stringify(userChat));
                dispatch({type: Actions.SET_ROOM, room: dataRoom});
                dispatch({type: Actions.SET_MESSAGES, messages: dataRoom.messages});
                setLoading(false);
                setError(false);
                dispatch({type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: id});
            }).catch(err => {
                dispatch({type: Actions.SET_ROOM, room: null});
                dispatch({type: Actions.SET_MESSAGES, messages: []});
                setLoading(false);
                if (!err.response || err.response.status !== 404) setError(true);
            });
        }
        else{ // Lấy room dựa theo username
            const channelLivechat = localStorage.getItem("channel_client");

            getRoomLivechat({username: user.username, channel_social_id: channelLivechat}).then(res => {
                let dataRoom = res.data.room;
                let userChat = dataRoom.owner;
                sessionStorage.setItem('user_chat', JSON.stringify(userChat));
                dispatch({type: Actions.SET_ROOM, room: dataRoom});
                dispatch({type: Actions.SET_MESSAGES, messages: dataRoom.messages});
                setLoading(false);
                setError(false);
                dispatch({type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: id});

                // Phát sự kiện toàn cục để thông báo API đã sẵn sàng
                window.parent.postMessage({type: 'LIVECHAT_READY', data: dataRoom}, '*');

            }).catch(err => {
                dispatch({type: Actions.SET_ROOM, room: null});
                dispatch({type: Actions.SET_MESSAGES, messages: []});
                setLoading(false);
                if (!err.response || err.response.status !== 404) setError(true);
            });
        }

        // setShowReply(false);
        // setDataReply(false);
    }, [id]);

    const Loading = () => (
        <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <ClipLoader
                size={60}
                color={"#0067ac"}
                loading={loading}
            />
        </div>
    );

    const NotFound = () => (
        <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <div className="notfound">Room Not Found</div>
            <div className="notfound-extended">
                This room does not exist.<br/>
                This is probably a broken URL.
            </div>
        </div>
    );

    const Error = () => (
        <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <div className="notfound">Network Error</div>
            <div className="notfound-extended">
                Could not reach server.
            </div>
        </div>
    );

    const Content = () => (
        <Messages canSend={canSend}/>
    );

    const reloadRooms = () => {
        getRooms(roomFilter)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    const SendForm = () => {
        if(chatMode === 'client' 
            && user 
            && user.firstName !== '' 
            && user.firstName.includes('Guest') 
            && room?.channel?.show_form_livechat === 1){
            return <></>;
        }
        
        if(!canSend && onSendMessTrans){
            return <BottomBarNotSend/>
        }
        else{
            return <BottomBar/> 
        }
    }

    return (
        <div className="content uk-flex uk-flex-column uk-flex-between">
            <TopBar back={back} loading={loading} reloadRooms={reloadRooms}/>
            {loading && <Loading/>}
            {error && <Error/>}
            {!room && !loading && !error && <NotFound/>}
            {room && !loading && <Content/>}
            <SendForm/>
            {/* <ModalForward /> */}
        </div>
    );
}

export default Conversation;

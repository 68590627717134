import React from "react";
import './Info.sass';
import logo from '../../../assets/logo.png';
import {Image} from "react-bootstrap";
import {useGlobal} from "reactn";

const Info = () => {
    const version = useGlobal('version')[0];

    return (
        <div className="info">
            <div className="top">
                <div className="logo">
                    <Image src={logo} alt="Picture" />
                </div>
                <div className="text">
                    Welcome to Chathub!<br/><br/>
                </div>
            </div>
            <div className="text">
                Copyright &copy; Epoints<br />
                v{version}
            </div>
        </div>
    );
};

export default Info;

import React, {useEffect, useState, useContext} from 'react';
import {useSelector} from "react-redux";
import "./LivechatFormStarted.sass";
import { AppContext } from "../../../context/AppContext";
import {useGlobal} from "reactn";
import { Button, Form, Input } from 'antd';
import registerLivechatApi from "../../../actions/registerLivechat";
import 'antd/dist/reset.css';

const LiveChatFormStarted = () => {
    const { i18n } = useContext(AppContext);
    const [user, setUser] = useGlobal('user');
    const room = useSelector(state => state.io.room);

    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 14,
        },
    };

    useEffect(() => {

    }, []);

    // Hàm xử lý khi form submit
    const onSubmit = (values) => {
        setLoading(true);

        // Xử lý dữ liệu form tại đây, ví dụ gửi dữ liệu lên server
        registerLivechatApi({user_id: user.id, full_name: values.full_name, phone: values.phone, room_id: room._id, channel_id: room.channel._id})
            .then((response) => {
                let result = response.data;
                if(result.error === 0){
                    setUser({...user, firstName: values.full_name, phone: values.phone});
                }

                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const getColorLivechat = () => {
        if(room && room.source === 'client' && room.channel.color){
            return {backgroundColor: room.channel.color};
        }

        return {};
    }

    return (
        <div className="live-chat-form-started">
            <Form
                {...formItemLayout}
                layout={'vertical'}
                form={form}
                initialValues={{
                    layout: 'vertical',
                }}
                onFinish={onSubmit}
                style={{
                maxWidth: 600,
            }}
            >
                <Form.Item label={i18n.t('full_name')} name="full_name" rules={[{ required: true, message: i18n.t('please_enter_full_name') }]}>
                    <Input placeholder={i18n.t('full_name')} />
                </Form.Item>
                <Form.Item label={i18n.t('phone')} name="phone" rules={[
                    {required: true, message: i18n.t('please_enter_phone')},
                    {pattern: /^[0-9]{9,10}$/, message: 'Số điện thoại không hợp lệ!'}
                ]}>
                    <Input maxLength={10} placeholder={i18n.t('phone')}/>
                </Form.Item>
                <Form.Item style={{ marginBottom: '10px' }}>
                    <Button className="mt-2" type="primary" style={getColorLivechat()} loading={loading} htmlType="submit">{i18n.t('started')} Chat</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default LiveChatFormStarted;
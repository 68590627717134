import React, {useEffect} from 'react';
import Div100vh from 'react-div-100vh';
import CreateGroup from "../../features/Group/Create";
import CreateGroup2 from "../../features/Group/Create2";
import Panel from "../../features/Panel";
import Details from "../../features/Details";
import "./Home.sass";
import {useGlobal} from "reactn";
import Conversation from "../../features/Conversation";
import Meeting from "../../features/Meeting";
import Welcome from "../../features/Welcome";
import NotFound from "../../features/NotFound";
import {Route, Switch, useLocation} from "react-router-dom";
import Admin from "../../features/Admin";

const Home = () => {
    const location = useLocation();
    const [over, setOver] = useGlobal('over');
    const [showPanel, setShowPanel] = useGlobal('showPanel');
    const panel = useGlobal('panel')[0];
    const [chatMode, setChatMode] = useGlobal('chatMode');

    useEffect(() => {
        if (location.pathname !== '/') setOver(true);
        if (location.pathname.startsWith('/client')){
            setChatMode('client');
            setShowPanel(false);
        }
    }, [location, setChatMode, setOver, setShowPanel]);

    const getPanel = () => {
        switch (panel) {
            case 'createGroup':
                return <CreateGroup/>
            case 'createGroup2':
                return <CreateGroup2/>
            default:
                return <Panel/>
        }
    }

    return (
        <Div100vh>
            <div className={`app${chatMode === 'client' ? ' chat-client' : ' chat-social'}`}>
                {chatMode !== 'client' && <Panel/>}
                <div className={`main uk-flex uk-flex-column${over ? ' over' : ''}${over === false ? ' exit' : ''}`}>
                    <Switch>
                        <Route exact path="/" component={Welcome} />
                        <Route exact path="/admin" component={Admin} />
                        <Route exact path="/meeting/:id" component={Meeting} />
                        <Route exact path="/room/:id" component={Conversation} />
                        <Route exact path="/client" component={Conversation} />
                        <Route exact path="/user/:uid" component={Conversation} />
                        <Route exact path="/room/:id/info" component={Details} />
                        <Route path="/" component={NotFound} />
                    </Switch>
                </div>
                {chatMode !== 'client' && <Details/>}
            </div>
        </Div100vh>
    );
}

export default Home;

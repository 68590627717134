import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import moment from 'moment';
import OverviewReport from "../components/ReportType/OverviewReport";
import PageReport from "./ReportType/PageReport";
import StaffReport from "./ReportType/StaffReport";
import CustomerReport from "./ReportType/CustomerReport";
import TagReport from "./ReportType/TagReport";
import RangePickerCustom from "../../../components/RangePickerCustom";

const ContentReport = ({selectedChannel}) => {
    const [selectedTime, setSelectedTime] = useState([
        moment().subtract(30, 'days').format('DD/MM/YYYY'), 
        moment().format('DD/MM/YYYY')
    ]);
    const location = useLocation();

    const getTitlePage = () => {
        if(location.pathname === '/report'){
            return 'Tổng quan';
        }
        else if(location.pathname === '/report/page'){
            return 'Trang';
        }
        else if(location.pathname === '/report/staff'){
            return 'Nhân viên';
        }
        else if(location.pathname === '/report/customer'){
            return 'Tương tác';
        }
        else if(location.pathname === '/report/tag'){
            return 'Thẻ hội thoại';
        }
    }

    useEffect(() => {

    }, []);

    return (
        <div className='page-statistic-content'>
            <div className='page-statistic-content-body'>
                <div className='overview-statistic'>
                    <div className="overview-statistic-header">
                        <div className="title">{getTitlePage()}</div>
                        <div className="filter d-flex">
                            <RangePickerCustom selectedDateRange={selectedTime} setSelectedDateRange={setSelectedTime}/>
                        </div>
                    </div>
                    <div className="main-content">
                        <Switch>
                            <Route exact path="/report" component={props => <OverviewReport {...props} selectedChannel={selectedChannel} selectedTime={selectedTime} />} />
                            <Route exact path="/report/page" component={props => <PageReport {...props} selectedChannel={selectedChannel} selectedTime={selectedTime} />} />
                            <Route exact path="/report/staff" component={props => <StaffReport {...props} selectedChannel={selectedChannel} selectedTime={selectedTime} />} />
                            <Route exact path="/report/customer" component={props => <CustomerReport {...props} selectedChannel={selectedChannel} selectedTime={selectedTime} />} />
                            <Route exact path="/report/tag" component={props => <TagReport {...props} selectedChannel={selectedChannel} selectedTime={selectedTime} />} />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentReport;
